import TransUnionQuestionnaire from '~/pages/rental-application/screening/TransUnionQuestionnaire';

const TransUnionQuestionnairePage = () => {
  return (
    <div class="relative flex h-full grow flex-col">
      <TransUnionQuestionnaire />
    </div>
  );
};

export default TransUnionQuestionnairePage;
