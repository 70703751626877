import { RENTAL_APPLICATION_NOT_FOUND } from '~/assets/strings';
import { rentalApplicationGateway } from '~/gateways/rentalApplicationGateway';
import { TransUnionAnswer, TransUnionAnswerSheet } from '~/types/RentalApplication';
import { MagicUseCase } from '~/use-cases/magicUseCase';
import { GetRentalApplicationStatusUseCase } from '../application-steps/getRentalApplicationStatusUseCase';

export class SendRentalApplicationScreeningAnswersUseCase extends MagicUseCase {
  protected async runLogic(answers: TransUnionAnswer[]) {
    const rentalApplicationInfo = this.getState().user.rentalApplication;
    if (!rentalApplicationInfo || !rentalApplicationInfo.application) {
      throw new Error(RENTAL_APPLICATION_NOT_FOUND);
    }
    const examId = this.getState().user.rentalApplication.transUnionScreening?.examId;
    const answerSheet: TransUnionAnswerSheet = {
      examId,
      answers: answers,
    };
    const credentials = rentalApplicationInfo.application.credentials;
    const status = await rentalApplicationGateway.sendTransUnionScreeningAnswers(credentials, answerSheet);
    if (rentalApplicationInfo.transUnionScreening) {
      rentalApplicationInfo.transUnionScreening.status = status;
    }
    await new GetRentalApplicationStatusUseCase(this.eventEmitter).execute(credentials);
  }
}
